var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layoutContent noNavTabCard"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('home-title',{staticClass:"desWap",attrs:{"description":_vm.description,"showpic":_vm.getConfigVal(_vm.curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')}}),_c('div',{staticClass:"contentWap"},[_c('KValidationObserver',{ref:"observer"},[_c('k-form',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.isPrevent.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"layoutCard"},[_c('div',{staticClass:"cardHead"},[_vm._v(" "+_vm._s(_vm.$g("personalData"))+" ")]),_c('div',{staticClass:"cardBody"},[(
                _vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'photoImg',
                  'vIf'
                )
              )?_c('k-form-group',{attrs:{"id":"photoImg","label-suffix":"photo","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('k-img',{staticStyle:{"max-height":"100px"},attrs:{"src":_vm.form.photoPath}})],1),_c('div',{staticClass:"d-flex flex-wrap align-items-start mt-2"},[(!_vm.plaintext)?_c('k-button',{staticClass:"mb-1 mr-2",on:{"click":_vm.changePhoto}},[_vm._v(" buttons.upload ")]):_vm._e(),(!_vm.plaintext)?_c('k-button',{staticClass:"mb-1",attrs:{"variant":"utility"},on:{"click":_vm.removePhoto}},[_vm._v(" buttons.remove ")]):_vm._e()],1)])]):_vm._e(),_c('k-form-group',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label-suffix":"voterId","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('div',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.form.voterId)+" ")])]),_c('k-form-group',{attrs:{"label-class":"require-mark","label-suffix":"firstName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"name":"firstName","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"firstName","plaintext":_vm.plaintext ||
                    _vm.nonEditableFields[_vm.nonEditableFieldsKey.FirstName],"aria-label":_vm.$g('firstName')},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}])})],1),_c('k-form-group',{attrs:{"label-suffix":"middleName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"name":"middleName","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"middleName","plaintext":_vm.plaintext ||
                    _vm.nonEditableFields[_vm.nonEditableFieldsKey.MiddleName],"aria-label":_vm.$g('middleName')},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}})]}}])})],1),_c('k-form-group',{attrs:{"label-suffix":"lastName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":"require-mark"}},[_c('KValidationProvider',{attrs:{"name":"lastName","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"lastName","plaintext":_vm.plaintext ||
                    _vm.nonEditableFields[_vm.nonEditableFieldsKey.LastName],"aria-label":_vm.$g('lastName')},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}])})],1),(
                _vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'suffixInput',
                  'labelSuffix'
                )
              )?_c('k-form-group',{attrs:{"label-suffix":"Suffix","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-form-select',{attrs:{"plaintext":_vm.plaintext,"options":_vm.suffixOptions},model:{value:(_vm.form.suffix),callback:function ($$v) {_vm.$set(_vm.form, "suffix", $$v)},expression:"form.suffix"}})],1):_vm._e(),_c('k-form-group',{attrs:{"label-suffix":_vm.isDob ? 'DateOfBirth' : 'yearOfBirth',"label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":!_vm.nonEditableFields[_vm.nonEditableFieldsKey.Dob]
                  ? 'require-mark'
                  : ''}},[_c('KValidationProvider',{attrs:{"name":"dob","rules":{
                  validateDateIsRequired:
                    !_vm.nonEditableFields[_vm.nonEditableFieldsKey.Dob],
                  validateDateFormat:
                    !_vm.nonEditableFields[_vm.nonEditableFieldsKey.Dob],
                  dob: !_vm.nonEditableFields[_vm.nonEditableFieldsKey.Dob],
                  required: !_vm.nonEditableFields[_vm.nonEditableFieldsKey.Dob],
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),(_vm.isDob)?_c('k-form-datepicker',{attrs:{"plaintext":_vm.plaintext || _vm.nonEditableFields[_vm.nonEditableFieldsKey.Dob],"aria-label":_vm.$g('dob')},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}}):_vm._e(),(!_vm.isDob)?_c('k-form-select',{attrs:{"options":_vm.dobOptions,"plaintext":_vm.plaintext || _vm.nonEditableFields[_vm.nonEditableFieldsKey.Dob]},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}}):_vm._e()]}}])})],1),(
                _vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'politicalParty',
                  'vIf'
                )
              )?_c('k-form-group',{attrs:{"label-suffix":"party","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('k-form-select',{attrs:{"options":_vm.partys,"plaintext":_vm.plaintext || _vm.nonEditableFields[_vm.nonEditableFieldsKey.PartyId],"aria-label":_vm.$g('party')},model:{value:(_vm.form.politicalPartyId),callback:function ($$v) {_vm.$set(_vm.form, "politicalPartyId", $$v)},expression:"form.politicalPartyId"}})],1):_vm._e(),(
                _vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'preferredPronousSelect',
                  'vIf'
                )
              )?_c('k-form-group',{attrs:{"id":"preferredpronousSelect","label-suffix":"preferredPronous","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.preferredpronousList,"plaintext":_vm.plaintext},on:{"change":_vm.changePreferredpronous},model:{value:(_vm.form.preferredPronousId),callback:function ($$v) {_vm.$set(_vm.form, "preferredPronousId", $$v)},expression:"form.preferredPronousId"}})]}}],null,false,3085139694)})],1):_vm._e(),(
                _vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'preferredPronousSelect',
                  'vIf'
                ) && _vm.showPreferredPronousText
              )?_c('k-form-group',{attrs:{"id":"preferredPronousInput","label-suffix":"","label-class":_vm.showPreferredPronousText ? 'require-mark' : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                  required: _vm.showPreferredPronousText,
                  max: 200,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"preferredPronousText","plaintext":_vm.plaintext},model:{value:(_vm.form.preferredPronousTitle),callback:function ($$v) {_vm.$set(_vm.form, "preferredPronousTitle", $$v)},expression:"form.preferredPronousTitle"}})]}}],null,false,4261355419)})],1):_vm._e()],1)]),_c('div',{staticClass:"layoutCard"},[_c('div',{staticClass:"cardHead"},[_vm._v(" "+_vm._s(_vm.$g("residentialAddress"))+" ")]),_c('address-form',{staticClass:"cardBody addressHandle",attrs:{"identifier":"residential","address-label":"residentialAddress","address":_vm.residentialAddressForm,"is-plain-text":_vm.plaintext || _vm.nonEditableFields['ResidentialStreetName'],"street-types":_vm.streetTypes,"state-types":_vm.stateTypes,"street-posts":_vm.streetPosts,"loading":_vm.loading,"addressformat":_vm.addressformat,"validate":true,"is-show-overseas-address":false,"overseas-validate":"","non-editable-fields":_vm.nonEditableFields},on:{"addressNativeChanged":_vm.addressNativeChanged}})],1),_c('div',{staticClass:"layoutCard"},[_c('div',{staticClass:"cardHead"},[_vm._v(" "+_vm._s(_vm.$g("postalAddress"))+" ")]),_c('address-form-overseas',{ref:"addressFormOverseas",staticClass:"cardBody addressHandle",attrs:{"identifier":"postal","address-label":"postalAddress","address":_vm.postalAddressForm,"is-plain-text":_vm.plaintext || _vm.nonEditableFields['MailingAddressLine1'],"loading":_vm.loading,"address-copy-label":"sara","address-to-copy":_vm.residentialAddressForm,"overseas-validate":false,"mailing-address-format":_vm.mailingaddressformat,"non-editable-fields":_vm.nonEditableFields,"has-oversea-country":_vm.getConfigVal(
                _vm.curPageConfigAndDefaultPageConfig,
                'addressFormOverseasOverseaCountry',
                'vIf'
              ) &&
              _vm.mailingaddressformat.find((it) => it.name === 'AddressLine4') !=
                null}})],1),(_vm.loadCompeleted)?_c('BasicinfoNotificationPreferences',{attrs:{"edit":_vm.BasicinfoNotificationPreferencesEdit,"non-editable-fields":_vm.nonEditableFields},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e(),_c('k-form-group',{staticClass:"wapBtnCenter",staticStyle:{"display":"block","margin-top":"25px"},attrs:{"label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('button',{staticClass:"btn btnPrimary w-150",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$g("save"))+" ")])])],1)],1),_c('k-popwindow',{staticClass:"content-form",attrs:{"id":"popCropper","title":_vm.$g('popwindow.photo'),"before-close":_vm.onBeforeClose,"check-selected":false},on:{"ok":_vm.onPopCropper}},[_c('PhotoTailor',{ref:"PhotoTailor",attrs:{"getimgurldata":_vm.getImgUrlData,"request-api":_vm.requertFunction,"ref-id":_vm.form.id}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }