import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

export const getPageFieldsConfig = (para) => {
  return http.get("/api/page-fields-config/" + para.listId);
};

export const savePageFieldsConfig = (data) => {
  return http.post("/api/page-fields-config", data, { useBody: true });
};

export const getYesNoDropDown = (data) => {
  return http.get("/api/enum/yes-no", data);
};

export const getDateTimeNow = (data) => {
  return http.get("/api/common/GetDateTimeNow", data);
};

export const getAddressFormat = () => {
  return http.get(
    `/anon/address/format`,
    {},
    { loading: false, baseURL: globalEnv.VUE_APP_STD_URL },
  );
};

export const getMailingAddressFormat = () => {
  return http.get(
    `/anon/address/mailing-format`,
    {},
    { loading: false, baseURL: globalEnv.VUE_APP_STD_URL },
  );
};

export const getSuffixList = (data) => {
  return http.get("/anon/suffix/list", data, {
    systemPATH: globalEnv.VUE_APP_ADMIN_SYSTEM_PATH,
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
}

export const getWorkDateShifts = (data) => {
  return http.get(
    "/api/ele-jobtitle/work-date-shifts",
    data,
    { loading: false, baseURL: globalEnv.VUE_APP_STD_URL },
  );
};

export const isExit = (model) => {
  return http.post(`/api/docs/actions/IsExit`, { model }, { loading: true,  });
};
export const downloadDocumentUrl = (model) => {
  return http.down("/api/docs/actions/download", { model }, { loading: false, });
};

export const getSysParam = () => {
  return http.get(`/anon/getsystemparpam/IsYearOfBirth`, null, {
    systemPATH: "admin",
    loading: false,
    baseURL: globalEnv.VUE_APP_ADMIN_URL,
  });
};
