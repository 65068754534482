<template>
  <div class="layoutContent noNavTabCard">
    <k-alert-msg ref="lblMsg" />
    <home-title
      :description="description"
      class="desWap"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    />
    <div class="contentWap">
      <KValidationObserver ref="observer">
        <k-form @keydown.enter="isPrevent" @submit.prevent="save">
          <div class="layoutCard">
            <div class="cardHead">
              {{ $g("personalData") }}
            </div>
            <div class="cardBody">
              <k-form-group
                id="photoImg"
                label-suffix="photo"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                v-if="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'photoImg',
                    'vIf'
                  )
                "
              >
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="mr-2">
                    <k-img :src="form.photoPath" style="max-height: 100px" />
                  </div>
                  <div class="d-flex flex-wrap align-items-start mt-2">
                    <k-button
                      @click="changePhoto"
                      v-if="!plaintext"
                      class="mb-1 mr-2"
                    >
                      buttons.upload
                    </k-button>
                    <k-button
                      @click="removePhoto"
                      v-if="!plaintext"
                      variant="utility"
                      class="mb-1"
                    >
                      buttons.remove
                    </k-button>
                  </div>
                </div>
              </k-form-group>
              <k-form-group
                label-suffix="voterId"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                v-show="false"
                label-for="noneBind"
              >
                <div class="col-form-label">
                  {{ form.voterId }}
                </div>
              </k-form-group>
              <k-form-group
                label-class="require-mark"
                label-suffix="firstName"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  name="firstName"
                  rules="required|max:200"
                  v-slot="{ errors }"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input
                    id="firstName"
                    v-model="form.firstName"
                    :plaintext="
                      plaintext ||
                      nonEditableFields[nonEditableFieldsKey.FirstName]
                    "
                    :aria-label="$g('firstName')"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-suffix="middleName"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  name="middleName"
                  rules="max:200"
                  v-slot="{ errors }"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input
                    id="middleName"
                    v-model="form.middleName"
                    :plaintext="
                      plaintext ||
                      nonEditableFields[nonEditableFieldsKey.MiddleName]
                    "
                    :aria-label="$g('middleName')"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-suffix="lastName"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                label-class="require-mark"
              >
                <KValidationProvider
                  name="lastName"
                  rules="required|max:200"
                  v-slot="{ errors }"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input
                    id="lastName"
                    v-model="form.lastName"
                    :plaintext="
                      plaintext ||
                      nonEditableFields[nonEditableFieldsKey.LastName]
                    "
                    :aria-label="$g('lastName')"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                label-suffix="Suffix"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                v-if="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'suffixInput',
                    'labelSuffix'
                  )
                "
              >
                <k-form-select
                  v-model="form.suffix"
                  :plaintext="plaintext"
                  :options="suffixOptions"
                />
              </k-form-group>
              <k-form-group
                :label-suffix="isDob ? 'DateOfBirth' : 'yearOfBirth'"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                label-for="noneBind"
                :label-class="
                  !nonEditableFields[nonEditableFieldsKey.Dob]
                    ? 'require-mark'
                    : ''
                "
              >
                <KValidationProvider
                  name="dob"
                  :rules="{
                    validateDateIsRequired:
                      !nonEditableFields[nonEditableFieldsKey.Dob],
                    validateDateFormat:
                      !nonEditableFields[nonEditableFieldsKey.Dob],
                    dob: !nonEditableFields[nonEditableFieldsKey.Dob],
                    required: !nonEditableFields[nonEditableFieldsKey.Dob],
                  }"
                  v-slot="{ errors }"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-datepicker
                    v-model="form.dob"
                    :plaintext="
                      plaintext || nonEditableFields[nonEditableFieldsKey.Dob]
                    "
                    :aria-label="$g('dob')"
                    v-if="isDob"
                  />
                  <k-form-select
                    v-model="form.dob"
                    :options="dobOptions"
                    :plaintext="
                      plaintext || nonEditableFields[nonEditableFieldsKey.Dob]
                    "
                    v-if="!isDob"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                v-if="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'politicalParty',
                    'vIf'
                  )
                "
                label-suffix="party"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                label-for="noneBind"
              >
                <k-form-select
                  v-model="form.politicalPartyId"
                  :options="partys"
                  :plaintext="
                    plaintext || nonEditableFields[nonEditableFieldsKey.PartyId]
                  "
                  :aria-label="$g('party')"
                />
              </k-form-group>
              <k-form-group
                id="preferredpronousSelect"
                label-suffix="preferredPronous"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                v-if="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'preferredPronousSelect',
                    'vIf'
                  )
                "
              >
                <KValidationProvider v-slot="{ errors }">
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-select
                    :options="preferredpronousList"
                    v-model="form.preferredPronousId"
                    :plaintext="plaintext"
                    @change="changePreferredpronous"
                  />
                </KValidationProvider>
              </k-form-group>
              <k-form-group
                id="preferredPronousInput"
                v-if="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'preferredPronousSelect',
                    'vIf'
                  ) && showPreferredPronousText
                "
                label-suffix=""
                :label-class="showPreferredPronousText ? 'require-mark' : ''"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <KValidationProvider
                  :rules="{
                    required: showPreferredPronousText,
                    max: 200,
                  }"
                  v-slot="{ errors }"
                >
                  <k-alert :show="errors.length > 0" variant="warning">
                    {{ errors[0] }}
                  </k-alert>
                  <k-form-input
                    id="preferredPronousText"
                    :plaintext="plaintext"
                    v-model="form.preferredPronousTitle"
                  />
                </KValidationProvider>
              </k-form-group>
            </div>
          </div>
          <div class="layoutCard">
            <div class="cardHead">
              {{ $g("residentialAddress") }}
            </div>
            <address-form
              identifier="residential"
              address-label="residentialAddress"
              :address="residentialAddressForm"
              :is-plain-text="
                plaintext || nonEditableFields['ResidentialStreetName']
              "
              :street-types="streetTypes"
              :state-types="stateTypes"
              :street-posts="streetPosts"
              :loading="loading"
              :addressformat="addressformat"
              :validate="true"
              :is-show-overseas-address="false"
              overseas-validate
              class="cardBody addressHandle"
              @addressNativeChanged="addressNativeChanged"
              :non-editable-fields="nonEditableFields"
            />
          </div>
          <div class="layoutCard">
            <div class="cardHead">
              {{ $g("postalAddress") }}
            </div>
            <address-form-overseas
              ref="addressFormOverseas"
              identifier="postal"
              address-label="postalAddress"
              :address="postalAddressForm"
              :is-plain-text="
                plaintext || nonEditableFields['MailingAddressLine1']
              "
              :loading="loading"
              address-copy-label="sara"
              :address-to-copy="residentialAddressForm"
              :overseas-validate="false"
              :mailing-address-format="mailingaddressformat"
              class="cardBody addressHandle"
              :non-editable-fields="nonEditableFields"
              :has-oversea-country="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'addressFormOverseasOverseaCountry',
                  'vIf'
                ) &&
                mailingaddressformat.find((it) => it.name === 'AddressLine4') !=
                  null
              "
            />
          </div>
          <BasicinfoNotificationPreferences
            :edit="BasicinfoNotificationPreferencesEdit"
            v-model="form"
            v-if="loadCompeleted"
            :non-editable-fields="nonEditableFields"
          />
          <k-form-group
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="noneBind"
            style="display: block; margin-top: 25px"
            class="wapBtnCenter"
          >
            <button type="submit" class="btn btnPrimary w-150">
              {{ $g("save") }}
            </button>
          </k-form-group>
        </k-form>
      </KValidationObserver>
      <k-popwindow
        id="popCropper"
        @ok="onPopCropper"
        :title="$g('popwindow.photo')"
        :before-close="onBeforeClose"
        class="content-form"
        :check-selected="false"
      >
        <PhotoTailor
          ref="PhotoTailor"
          :getimgurldata="getImgUrlData"
          :request-api="requertFunction"
          :ref-id="form.id"
        />
      </k-popwindow>
    </div>
  </div>
</template>
<script>
import BasicinfoNotificationPreferences from "./components/basicinfo-notificationPreferences";
import {
  originDataChangeMixin,
  repResult,
  storeage,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  yesNo,
  isPrevent,
} from "fe-infrastractures";
import {
  getMyProfileInfo,
  saveMyProfileInfo,
  changePhoto,
  getYearOfBirthOptions,
  getPreferredpronousList,
} from "@/api/myprofile";
import {
  getAddressFormat,
  getMailingAddressFormat,
  getSuffixList,
  getSysParam,
} from "@/api/common";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import { getPageDesc } from "@/api/login";
import HomeTitle from "@/views/components/homeTitle";
import noPhotoPath from "@/assets/img/photo-slices.png";
import {
  getStreetType,
  getStateType,
  getStreetPost,
  getPartys,
} from "@/api/application";

export default {
  components: {
    BasicinfoNotificationPreferences,
    HomeTitle,
  },

  mixins: [originDataChangeMixin],
  props: {
    plaintext: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      isPrevent,
      isDob: true,
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      dobOptions: [],
      description: "",
      tabIndex: 0,
      form: {
        id: storeage.getUserInfo()?.userId,
        backAddUserStatus: 1,
        boundaryList: null,
        candidateAddUseStatus: 1,
        candidatePortalStatus: null,
        createTime: "",
        dob: null,
        email: "",
        entityIds: null,
        firstName: "",
        helpAddUseStatus: 1,

        isOverseasAddress: null,
        isSameAsResidentialAddress: false,
        languageId: "",
        lastName: "",
        mailingAddressLine1: "",
        mailingAddressLine2: "",
        mailingAddressLine3: "",
        mailingCountry: "",
        middleName: "",
        mobilePhone: "",
        notDisturb: false,
        notDisturbFrom: null,
        notDisturbTo: null,
        objectId: null,
        phone: null,
        politicalPartyId: "",
        portalStatus: 1,
        portalStatusStr: null,
        preferredContactMethod: 1,
        residentialApartment: "",
        residentialBuilding: "",
        residentialBuildingName: null,
        residentialCountry: "",
        residentialLocality: "",
        residentialPostcode: "",
        residentialState: "",
        residentialStreetFraction: "",
        residentialStreetName: "",
        residentialStreetNumber: "",
        residentialStreetPost: "",
        residentialStreetPre: "",
        residentialStreetType: "",
        residentialUnit: "",
        roleIds: [],
        sms: null,
        status: 1,
        statusStr: null,
        subdivisionId: null,
        tenantID: "",
        useEmail: false,
        usePhone: false,
        useSms: false,
        userCode: "",
        username: "",
        voterId: null,
        workPhone: "",
        photoPath: noPhotoPath,
        photo: "",
        suffix: "",
        preferredPronousId: "",
        preferredPronousTitle: "",
        additionalPhoneNumber: "",
      },
      residentialAddressForm: {
        isSame: false,
        isOverseas: false,
        unit: "",
        streetNumber: "",
        streetName: "",
        streetType: "",
        city: "",
        state: "",
        postcode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        overseaCountry: "",
        country: "",
        streetFraction: "",
        streetPre: "",
        streetPost: "",
        building: "",
        apartment: "",
      },
      postalAddressForm: {
        isSame: false,
        isOverseas: false,
        unit: "",
        streetNumber: "",
        streetName: "",
        streetType: "",
        city: "",
        state: "",
        postcode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        overseaCountry: "",
        country: "",
        streetFraction: "",
        streetPre: "",
        streetPost: "",
        building: "",
      },
      streetTypes: [],
      streetPosts: [],
      stateTypes: [],
      partys: [],
      loading: false,
      loadCompeleted: false,
      addressformat: "",
      mailingaddressformat: [],
      suffixOptions: [],
      preferredpronousList: [],
      showPreferredPronousText: false,
      nonEditableFields: {
        ResidentialStreetName: false,
        MailingAddressLine1: false,
        PreferredContactMethod: false,
      },
      nonEditableFieldsKey: {
        FirstName: "FirstName",
        MiddleName: "MiddleName",
        LastName: "LastName",
        Dob: "Dob",
        ResidentialStreetName: "ResidentialStreetName",
        MailingAddressLine1: "MailingAddressLine1",
        PartyId: "PartyId",
        PreferredContactMethod: "PreferredContactMethod",
      },
    };
  },
  computed: {
    requertFunction: function () {
      return changePhoto;
    },
    BasicinfoNotificationPreferencesEdit() {
      if (this.nonEditableFields["PreferredContactMethod"]) {
        return false;
      }
      return !this.plaintext;
    },
  },
  methods: {
    changePreferredpronous(val) {
      let item = this.preferredpronousList.find((e) => e.value == val);
      if (item && item?.relateContent == yesNo.yes) {
        this.showPreferredPronousText = true;
      } else {
        this.showPreferredPronousText = false;
        this.form.preferredPronousTitle = "";
      }
    },
    initDobOptions() {
      getYearOfBirthOptions().then((result) => {
        this.dobOptions = result.data;
      });
    },
    changePhoto() {
      this.$bvModal.show("popCropper");
    },
    removePhoto() {
      this.form.photoPath = noPhotoPath;
      this.form.photo = "";
      this.$forceUpdate();
    },
    getImgUrlData(val) {
      this.form.photoPath = val.url;
      this.form.photo = val.id;
      this.$forceUpdate();
    },
    onPopCropper() {
      this.$refs.PhotoTailor.finish();
    },
    onBeforeClose() {
      return this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.save"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.saveYes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          return value != null && !value;
        });
    },
    onLoad() {
      getPageDesc(pageNameEnum.MyProfileBasicInformation).then((resp) => {
        this.description = resp.data.description;
      });
      if (this.form.id) {
        getMyProfileInfo(this.form.id).then((data) => {
          this.form = data.data;
          //   if (this.isDob) {
          //   this.form.dob = this.getYobDatepickerUtcTimestamp(this.form.dob);
          // }
          for (const item of Object.keys(this.nonEditableFieldsKey)) {
            if (this.form.nonEditableFields.find((c) => c == item)) {
              this.nonEditableFields[item] = true;
            } else {
              this.nonEditableFields[item] = false;
            }
          }
          console.log(this.nonEditableFields);
          // for (let index = 0; index < length; index++) {}
          this.residentialAddressForm = {
            isOverseas: data.data.isOverseasAddress ?? false,
            unit: data.data.residentialUnit,
            streetNumber: data.data.residentialStreetNumber,
            streetName: data.data.residentialStreetName,
            streetType: data.data.residentialStreetType,
            city: data.data.residentialLocality,
            state: data.data.residentialState,
            postcode: data.data.residentialPostcode,
            addressLine1: data.data.mailingAddressLine1,
            addressLine2: data.data.mailingAddressLine2,
            addressLine3: data.data.phyOverseaAddrLine3,
            overseaCountry: data.data.mailingCountry,
            country: data.data.residentialCountry,
            streetFraction: data.data.residentialStreetFraction,
            streetPre: data.data.residentialStreetPre,
            streetPost: data.data.residentialStreetPost,
            building: data.data.residentialBuilding,
            apartment: data.data.residentialApartment,
          };
          this.postalAddressForm = {
            isSame:
              data.data.isSameAsResidentialAddress ??
              getConfigVal(
                this.curPageConfigAndDefaultPageConfig,
                "isSame",
                "vIf"
              ),
            addressLine1: data.data.mailingAddressLine1,
            addressLine2: data.data.mailingAddressLine2,
            addressLine3: data.data.mailingAddressLine3,
            overseaCountry: data.data.mailingCountry,
          };

          this.loadCompeleted = true;

          if (this.form.photoPath == null || this.form.photoPath == "") {
            this.form.photoPath = noPhotoPath;
          }
          this.changePreferredpronous(this.form.preferredPronousId);
          this.setOrignData(this.form);
        });
      } else {
        this.postalAddressForm.isSame = getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "isSame",
          "vIf"
        );
      }
    },
    saveResponseFunc(m) {
      this.$refs.lblMsg.message(m.data.status, m.message);
      if (m.data.status == repResult.success) {
        this.setOrignData(this.form);
      }
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(() => {
          this.form = {
            ...this.form,
            ...this.mapResidentialAddressForm(),
            ...this.mapPostalAddressForm(),
          };
          this.form.mobilePhone = this.form.sms;
          saveMyProfileInfo(this.form).then((m) => {
            this.saveResponseFunc(m);
          });
        });
      }
    },
    mapResidentialAddressForm() {
      return {
        isOverseasAddress: this.residentialAddressForm.isOverseas ?? false,
        residentialUnit: this.residentialAddressForm.unit,
        residentialStreet: this.residentialAddressForm.streetNumber,
        residentialStreetNumber: this.residentialAddressForm.streetNumber,
        residentialStreetName: this.residentialAddressForm.streetName,
        residentialStreetType: this.residentialAddressForm.streetType,
        residentialLocality: this.residentialAddressForm.city,
        residentialState: this.residentialAddressForm.state,
        residentialPostCode: this.residentialAddressForm.postcode,
        mailingAddressLine1: this.residentialAddressForm.addressLine1,
        mailingAddressLine2: this.residentialAddressForm.addressLine2,
        mailingAddressLine3: this.residentialAddressForm.addressLine3,
        mailingCountry: this.residentialAddressForm.overseaCountry,
        residentialCountry: this.residentialAddressForm.country,
        residentialStreetFraction: this.residentialAddressForm.streetFraction,
        residentialStreetPre: this.residentialAddressForm.streetPre,
        residentialStreetPost: this.residentialAddressForm.streetPost,
        residentialBuilding: this.residentialAddressForm.building,
        residentialApartment: this.residentialAddressForm.apartment,
      };
    },
    mapPostalAddressForm() {
      return {
        isSameAsResidentialAddress: this.postalAddressForm.isSame ?? false,
        mailingAddressLine1: this.postalAddressForm.addressLine1,
        mailingAddressLine2: this.postalAddressForm.addressLine2,
        mailingAddressLine3: this.postalAddressForm.addressLine3,
        mailingCountry: this.postalAddressForm.overseaCountry,
      };
    },
    initial() {
      this.onLoad();
    },
    addressNativeChanged() {
      this.form = {
        ...this.form,
        ...this.mapResidentialAddressForm(),
        ...this.mapPostalAddressForm(),
      };
      if (this.postalAddressForm.isSame) {
        this.$refs.addressFormOverseas.onIsSameAddressChanged(true);
      }
    },
  },
  created() {
    getStreetType({}).then((data) => {
      const items = data.data;
      this.streetTypes = items || [];
    });
    getStateType({}).then((data) => {
      const items = data.data;
      this.stateTypes = items || [];
    });
    getAddressFormat().then((res) => {
      this.addressformat = res.data;
    });
    getMailingAddressFormat().then((res) => {
      this.mailingaddressformat = res.data;
    });
    getStreetPost({}).then((data) => {
      const items = data.data;
      this.streetPosts = items || [];
    });
    getPartys().then((res) => {
      this.partys = res.data;
    });
    getPreferredpronousList(storeage.getLanguageId()).then((res) => {
      this.preferredpronousList = res.data;
    });
    if (
      !this.getConfigVal(
        this.curPageConfigAndDefaultPageConfig,
        "suffixInput",
        "vIf"
      )
    ) {
      getSuffixList().then((res) => {
        this.suffixOptions = res.data;
      });
    }
    this.initDobOptions();
  },
  mounted() {
    getSysParam().then((res) => {
      this.isDob = res.data.isYearOfBirth != 1;
    });
    this.initial();
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .addressHandle {
    > div.form-row.form-group {
      > label.col-md-3 {
        display: none;
      }

      > div[class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;

        .space-between {
          min-height: calc(1.5em + 0.75rem + 2px);
        }
      }
    }
  }

  @media (min-width: 768px) {
    .addressHandle {
      > div.form-row.form-group {
        .text-md-left {
          text-align: right !important;
        }

        .col {
          flex: 0 0 58.333333%;
          max-width: 58.333333%;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .addressHandle {
      > div.form-row.form-group {
        .text-md-left {
          text-align: right !important;
        }

        .col {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
}
</style>
